<template>
  <div
    v-if="userDetailsLoader"
    class="flex items-center justify-center w-full py-24 h-52 sm:py-28 md:py-36 lg:py-52"
  >
    <base-svg
      class="h-4 w-4 mr-1 text-primary-red inline-block"
      src="icons/circleSpinner.svg"
      tag="span"
    />
    Loading ...
  </div>
  <template v-else>
    <div class="mb-6">
      <span class="font-semibold">Username:</span> {{ userPermissionsForm.username }}
    </div>
    <collapse-card
      :model-value="true"
    >
      <template #title>
        <span class="mr-2 text-sm font-medium">
          Properties
        </span>
      </template>
      <template #cardContent>
        <div class="py-2 px-4">
          <div class="grid grid-cols-2 gap-x-10 gap-y-4">
            <base-input
              v-model="userPermissionsForm.firstName"
              type="text"
              container-class="w-full flex items-center"
              label-class="label-w-46"
              label="First Name"
              disabled
            />
            <base-input
              v-model="userPermissionsForm.lastName"
              type="text"
              container-class="w-full flex items-center"
              label-class="label-w-46"
              label="Last Name"
              disabled
            />
            <base-input
              v-model="userPermissionsForm.username"
              type="text"
              container-class="w-full flex items-center"
              label-class="label-w-46"
              label="Login Name"
              disabled
            />
            <base-select
              v-model="userPermissionsForm.roles"
              class="flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Roles"
              label-class="label-w-46"
              multi-select
              show-default-option
              item-text-property="rolename"
              item-value-property="roleId"
              :options="roleOptions"
              @dropdownOpened="fetchRoles()"
            />
            <!-- <base-select
              v-model="userPermissionsForm.priveleges"
              class="h-7 flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Priveleges"
              label-class="label-w-46"
              :options="privelegesOptions"
              show-default-option
              @dropdownOpened="fetchPriveleges()"
            /> -->
            <!-- <base-select
              v-model="userPermissionsForm.preference"
              class="h-7 flex items-center text-sm text-left"
              placeholder="-- SELECT --"
              label="Apply Preference"
              label-class="label-w-46"
              :options="preferenceOptions"
              show-default-option
              @dropdownOpened="fetchPreference()"
            /> -->
            <base-input
              v-model="userPermissionsForm.email"
              type="text"
              container-class="w-full h-7 flex items-center"
              label-class="label-w-46"
              label="Email ID"
              disabled
            />
            <div class="w-full flex items-center">
              <div class="text-sm text-black mr-3.5 w-46 flex-shrink-0">
                Access
              </div>
              <div class="flex items-center">
                <base-radio
                  v-model="userPermissionsForm.access"
                  name="access"
                  label="Enabled"
                  value="Y"
                  :checked="userPermissionsForm.access === 'Y'"
                />
                <base-radio
                  v-model="userPermissionsForm.access"
                  name="access"
                  label="Disabled"
                  value="N"
                  class="ml-7"
                  :checked="userPermissionsForm.access === 'N'"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </collapse-card>
    <div class="flex justify-end my-6">
      <base-button
        variant="btn-primary"
        class="mr-3"
        text="Save"
        @click="editUser()"
      />
      <base-button
        variant="btn-link"
        text="Discard"
        @click="handleCancel()"
      />
    </div>
  </template>
</template>

<script>
import { defineAsyncComponent, ref, onMounted, computed } from 'vue';
import useUserPermissionsForm from './userPermissionsForm.js';
import { useStore } from 'vuex';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, SUCCESS, USER_DETAILS_UPDATE_SUCCESS, USER_DETAILS_UPDATE_FAILED } from '@/constants/alerts.js';

export default {
    name: 'Properties',

    components: {
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseRadio: defineAsyncComponent(() => import('@/components/generic-components/BaseRadio.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    emits: [
        'discard'
    ],

    setup (props, { emit }) {
        const store = useStore();
        const { userPermissionsForm, isUserPermissionFormUpdated } = useUserPermissionsForm();
        const { notificationsStack } = useToastNotifications();

        onMounted(async () => {
            await fetchRoles();
            fetchUserDetails();
        });

        const userDetailsLoader = ref(true);
        const fetchedUserDetails = ref({}) // computed(() => store.getters['users/getUserDetailsList']);
        const userDetailsLoading = ref(false);
        const fetchUserDetails = async () => {
            try {
                if (userDetailsLoading.value) {
                    return;
                }
                userDetailsLoading.value = true;
                userDetailsLoader.value = true;
                fetchedUserDetails.value = await store.dispatch('users/fetchUserDetailsByUserName', {
                    params: {
                        username: userPermissionsForm.value.username
                    }
                });

                userPermissionsForm.value.username = fetchedUserDetails.value.username;
                userPermissionsForm.value.firstName = fetchedUserDetails.value.firstname;
                userPermissionsForm.value.lastName = fetchedUserDetails.value.lastname;
                userPermissionsForm.value.email = fetchedUserDetails.value.email;
                userPermissionsForm.value.roles = fetchedUserDetails.value.roles?.length ? fetchedUserRoles.value.filter(role => fetchedUserDetails.value.roles.includes(role.rolename)).map(role => role.roleId) : [];
                userPermissionsForm.value.access = fetchedUserDetails.value.status;
            } catch (err) {
                console.error(err);
            } finally {
                userDetailsLoading.value = false;
                userDetailsLoader.value = false;
            }
        };

        const roleOptions = computed(() => {
            const filteredRolesOpt = fetchedUserRoles?.value?.filter(role => role?.roleId !== 'BAC_FINAL');
            return filteredRolesOpt || [];
        });

        const fetchedUserRoles = computed(() => store.getters['users/getAllRolesList']);
        const roleOptionsLoading = ref(false);
        const fetchRoles = async () => {
            try {
                if ((fetchedUserRoles.value && fetchedUserRoles.value.length > 0) || roleOptionsLoading.value) {
                    return;
                }
                roleOptionsLoading.value = true;
                await store.dispatch('users/fetchAllRoles');
            } catch (err) {
                console.error(err);
            } finally {
                roleOptionsLoading.value = false;
            }
        };

        const privelegesOptions = ref([
            'options 1',
            'options 2',
            'options 3',
            'options 4'
        ]);
        const preferenceOptions = ref([
            'options 1',
            'options 2',
            'options 3',
            'options 4'
        ]);
        const fetchPriveleges = () => {};
        const fetchPreference = () => {};

        // form actions logic
        const isUserDetailUpdating = ref(false);
        const editUser = async () => {
            if (isUserDetailUpdating.value) {
                return;
            }
            try {
                isUserDetailUpdating.value = true;
                await store.dispatch('users/updateUserRoles', {
                    bodyPayLoad: {
                        username: userPermissionsForm.value.username,
                        roleIds: userPermissionsForm.value.roles,
                        status: userPermissionsForm.value.access
                    }
                });
                isUserPermissionFormUpdated.value = true;
                notificationsStack.value.push({
                    type: SUCCESS,
                    message: USER_DETAILS_UPDATE_SUCCESS
                });
                handleCancel();
            } catch (err) {
                console.error(err);
                notificationsStack.value.push({
                    type: ERROR,
                    message: userPermissionsForm.value.roles.length === 0 ? 'Minimum one role is required' : USER_DETAILS_UPDATE_FAILED
                });
            } finally {
                isUserDetailUpdating.value = false;
            }
        };
        const handleCancel = () => {
            emit('discard');
        };

        return {
            userPermissionsForm,
            roleOptions,
            editUser,
            handleCancel,
            privelegesOptions,
            preferenceOptions,
            userDetailsLoader,
            fetchRoles,
            fetchPriveleges,
            fetchPreference
        };
    }
};
</script>
